<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">采购计划基本信息</el-col>
    </el-row>
    <el-form ref="detailForm" :disabled="fordisabled" :model="detailForm" :rules="rules" label-width="135px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="采购计划单号：" prop="purchasePlanCode">
            <el-input v-model="detailForm.purchasePlanCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态:" prop="purchasePlanCode" :class="$i18n.locale">
            <el-select v-model="detailForm.purchaseStatus" :placeholder="$t('page.selectPlaceholder')">
              <el-option label="草稿" value="0" />
              <el-option label="审批中" value="1" />
              <el-option label="审批通过" value="2" />
              <el-option label="部分转订单" value="3" />
              <el-option label="已完成" value="4" />
              <el-option label="审批拒绝" value="5" />
              <el-option label="已作废" value="9" />
              <el-option label="运营部审批中" value="11" />
              <el-option label="计划部审批中" value="12" />
              <el-option label="财务部审批中" value="13" />
              <el-option label="采购部审批中" value="14" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="数量：">
            <el-input v-model="detailForm.expectPairs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备货渠道">
            <el-input v-model="detailForm.stockChannelName" clearable filterable disabled />
          </el-form-item>
          <!-- <el-form-item label="采购金额：" prop="purchaseAmount">
            <el-input v-model="detailForm.purchaseAmount" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item> -->
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建人：" prop="createByName">
            <el-input v-model="detailForm.createByName" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建时间：" prop="createTime">
            <el-input v-model="detailForm.createTime" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="已生成采购数量：" prop="actualPairs">
            <el-input v-model="detailForm.actualPairs" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="未生成采购数量：" prop="unGeneratePairs">
            <el-input v-model="detailForm.unGeneratePairs" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="BU" prop="operatorGroup">
            <el-input v-model="detailForm.operatorGroup" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Operator" prop="operatorUser">
            <el-input v-model="detailForm.operatorUser" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="计划类型" prop="type">
            <el-radio-group v-model="detailForm.type">
              <el-radio :label="'0'">普通计划</el-radio>
              <el-radio :label="'1'">提前备货计划</el-radio>
              <el-radio :label="'2'">超买计划</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备注：" prop="remarks">
            <el-input v-model="detailForm.remarks" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">已生成采购明细</el-col>
    </el-row>
    <el-table
      ref="addOutboundTable"
      class="mb-3"
      :data="alreadydetailTable"
      :header-cell-style="{background:'#fafafa'}"
      max-height="600px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="purchaseOrderCode"
        label="采购订单号"
        width="120"
        align="center"
      />
      <el-table-column
        prop="style,color,size"
        label="规格型号"
        align="center"
      >
        <template slot-scope="scope">{{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}</template>
      </el-table-column>

      <el-table-column
        prop="platformName"
        label="平台"
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="站点"
        width="120"
        align="center"
      />
      <el-table-column
        prop="expectPairs"
        label="采购数量"
        width="100"
        align="center"
      />
      <el-table-column
        prop="actualPairs"
        label="生成采购数量"
        width="100"
        align="center"
      />
    </el-table>

    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">未生成采购明细</el-col>
    </el-row>
    <el-table
      ref="addOutboundTable"
      class="mb-3"
      :data="undetailTable"
      :header-cell-style="{background:'#fafafa'}"
      max-height="600px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="style,color,size"
        label="规格型号"
        align="center"
      >
        <template slot-scope="scope">{{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}</template>
      </el-table-column>

      <el-table-column
        prop="platformName"
        label="平台"
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="站点"
        width="120"
        align="center"
      />
      <el-table-column
        prop="expectPairs"
        label="采购数量"
        align="center"
      />
      <el-table-column
        prop="unGeneratePairs"
        label="未生成数量"
        width="100"
        align="center"
      />
      <el-table-column
        prop="actualPairs"
        label="已生成数量"
        width="100"
        align="center"
      />
    </el-table>
    <go-back margintop />
  </div>
</template>
<script>
import { queryPlanToOrder } from '@/api/scm-api'

import GoBack from '@/components/GoBack'
export default {
  components: {
    GoBack
  },
  data() {
    return {
      fordisabled: false, // 禁用文本框
      detailForm: {},
      undetailTable: [],
      alreadydetailTable: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      rules: {

      }
    }
  },
  computed: {
  },
  mounted() {
    if (this.$route.query && this.$route.query.disabled) {
      this.fordisabled = true
    }
    this._queryPlanToOrder()
  },
  methods: {

    async _queryPlanToOrder(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas } = await queryPlanToOrder({ purchasePlanCode: id })
        this.detailForm = datas.purchasePlanInfo // 基本明细
        this.detailForm.purchaseStatus = String(datas.purchasePlanInfo.purchaseStatus)
        this.undetailTable = datas.unGenerateList // 未生成采购明细
        this.alreadydetailTable = datas.alreadyGenerateList // 已生成采购明细
      }
    },
    getTotal(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('合计')
          return
        }
        if (column.property === 'actualPairs') {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }

  }
}
</script>

